
import { Link } from 'react-router-dom';

import "./styles.css";
import Elements from './Elements';
export default () => (
  <div className='body_card'>
      <Elements />

    <div className="birthdayCard">
      <div className="cardFront">
        <h3 className="happy">HAPPY BIRTHDAY Shreeshu!</h3>
        <p className='happy' style={{fontSize:"10px"}}>(Click to open)</p>
        <div className="balloons">
          <div className="balloonOne" />
          <div className="balloonTwo" />
          <div className="balloonThree" />
          <div className="balloonFour" />
        </div>
      </div>
      <div className="cardInside">
        <h3 className="back">To My Dear Best Friend</h3>
        <p className='card-para'>Happy Birthday Tommy,<br></br>
        Sorry, I am weak at giving Surprise Gifts. And also I can't able to hold the surprise with you. 
        I hope this is the only Surprise I can able to give you without revealing before. 
            <br></br><Link to="/message">Click here for the Wish</Link>
        </p>
        {/* <p className="name">-Ajay</p> */}
      </div>
    </div>
    </div>
  );
  