import React, {Component} from 'react';
 import './Elements.scss';
//  import '../App.css';

class Elements extends Component {
    render() {
        return(
            <div className='Elements'>

                <div className="snowflakes" aria-hidden="true">
<div classNameName="pyro">
             <div className="before"></div>
                <div className="after"></div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
  ❅
  </div>
  <div className="snowflake">
  ❆
  </div>
  <div className="snowflake">
    ❅
  </div>
  <div className="snowflake">
    ❆
  </div>
  <div className="snowflake">
    ❅
  </div>
  <div className="snowflake">
    ❆
  </div>
  <div className="snowflake">
    ❅
  </div>
  <div className="snowflake">
    ❆
  </div>

            
        
                
            
            
                </div>
                </div>
                
                </div>    
        )
    }

}

export default Elements;