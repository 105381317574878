import './message.css'


import { Link } from 'react-router-dom';
import Elements from './Elements';

const Wish = () => {
    return (
        <>
       <div className ="Quote-body">

        {/* <div style={{display:"none"}}> <TopHeader/></div> */}
        <div className="Quote-cards"> 
    
    <div className="Quote-card">
    {/* <p style={{textAlign: "right"}}><Link to="/">Home</Link></p> */}
    {/* <h1 className="Quote-h1">{getuserdata.title}</h1> */}
  
      <p className="wish-para"style={{whiteSpace: "pre-wrap"}}> <center>
      <b>Hai Shreeshu,<br></br>Happy Birthday</b> <br />
        Its your birthday today. Since you are asking for a paragraph for a very long time. I will present that to you in my style. 
        Whenever you asked a long paragraph about you, I will be escaping from you. But now it's Jan 5th. And I cannot escape from typing this loooongggg paragraph. 
        Before I begin, a small disclaimer: <b>Omit if there is any spelling and grammatical mistake and I was not a expressive person. Sorry in advanve  if this paragraph made you bored.</b>
        <br></br> 


        Hey Tommy, 
        Let me tell a kuty story, pay attention listen to me. <br /> 
Once there was a dense forest where all the animals got struck inside the forest. All those animals were in search of sunlight so that they can be in a habitable region. So they splitted into various directions finding a way for the sunlight. If they find a way they will inform other creatures and they will all get to the safe zone. 
<br />
Some animals went to one direction and they saw a tiny light in the dense forest. Thought that was a sunlight, those animals called rest of all the animals to the spot and they marched towards that bright light. 
When the animals are nearing the light, the light starts moving and all the animals were chasing behind light in the dense forest. While chasing they unknowingly came out of the dense forest and saw the sun which shines bright in the sky.  Actually the light was nothing but a firefly. The firefly produced some light in the dark and make the way for the animals and that firefly helped all the animals to get out of the dark. 
<br />
The presence of that firefly is more important for that animals to get out of dark. Likewise you are the <b>firefly</b> who healed everyone from their dark and your presence makes a way for the bright sunshine to the people around you. 
<br />
You are the only person whom I can share anything comfortably. I think you are the only one who saw me in this version. That’s because I can be myself around you. You are the first person who comes to my mind to share something to someone. Speaking to you helps to reduce my stress and you are always my <b>mental support</b>. Being with you, I had only positive energy without any negativity. Thankyou for having my back and support though all the time. Whoever and whatever someone says negatively about you just ignore. I will always be there for you and with you. You are such a <b>amazing person with extraordinary talents</b> which was known only to few people. And please <b>reduce your overthinking and focus on the current process</b>. You have an <b>abundant talent</b> and try to use it to the fullest and I will be there to support you as always. 
<br />
Finally, <b>You are always my best and closest friend</b> and will be there for you forever whatever the situation maybe. And you are the most <b>comfortable</b> person to share all my polambals. Lets not allow anyone to break the friendship bond. We will stick together and overcome challenges keeping our bond strong and unbreakable. Thank you for being there for me and thanks for everything. <b>Your friendship and your presence for me is the greatest gift I ever had</b>.  
<br />
Once again <b>Happy Birthday Shreeshu..</b>
<br /> 
        


       <br></br> 
       I have an another thing for you, I don't know I will be physically present on you birthday. So let me celebrate your birthday virtually along with you. Before that <b>if you are on phone, please open this website in your laptop for better experiece.</b> <br></br>
       <Link to="https://hbdshreeshu-candleblow.ajaykrishnan.in/"> Click here for Surprise</Link> and <b>make sure you give access to your microphone.</b>
        </center>  </p>
    <p className="wish-para" style={{textAlign:"right"}}>-Ajay</p>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
  
    </div>
        <Elements />
  </div>
  </div>
        </>
    )
}

export default Wish;