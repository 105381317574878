import { Routes, Route } from 'react-router-dom';
import Card from './Card';
import Wish from './Wish';
import Login from './Login';

const Navigatior = () => { 
    return(
        
        <Routes>
            <Route path = "/" element = {<Login />} />
            <Route path = "/hbdtommy" element = {<Card />} />
            <Route path = "/message" element = {<Wish />} />

        </Routes>

    )
    
}


export default Navigatior;