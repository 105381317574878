import React from 'react';
import {Button, TextField, Alert, AlertTitle} from '@mui/material';
import {Link, Navigate} from 'react-router-dom';



import './Login.css';


class Login extends React.Component {
    constructor() {
        super()
        this.state = {
            username:'',
            password:''
        };
        this.handleusername = this.handleusername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);
    }
    handleusername(evt) {
        this.setState({
          username: evt.target.value,
        });
      };
    
      handlePassword(evt) {
        this.setState({
          password:evt.target.value,
        });
      };

      dismissError() {
        this.setState({ error: '' });
      }

      handleSubmit(evt) {
          evt.preventDefault();
          const {username} = this.state
          const {password} = this.state


        //   localStorage.setItem('Login username', username);
        //   localStorage.setItem('Login Password', password);
          if (username !== "hbdtommy" || password !== "05cheechu@01") { 
            return (
                this.setState({error: 'Account not found or \n Invalid username or password'})
                // alert("Invalid Credentials"),
                
                // window.location.replace('/')
                );
          } else{
              return(
                window.location.replace('/#/hbdtommy')
                
                // <Navigate to="/#/hbd0" />
                // this.props.history.push('/#/hbd0')
                
              )
          }
          
        //   
      }
    render(){
        
        return (
            <div className="App">
              <div className="card">
              <p>
                  <TextField required id="standard-required" label="Username" variant="standard" value={this.state.username} onChange={this.handleusername} />
                  <br></br><br></br>
                  <TextField required id="standard-password-input" label="Password" variant="standard" type="password" value={this.state.password} onChange={this.handlePassword} />
              
           
              <br></br> <br></br>
                  {/* <Link to="/hbd0" style={{textDecoration:"none"}}> */}
              <Button variant="outlined" onClick={this.handleSubmit}> Login
                  
                  </Button>
                  
                  {
                      this.state.error && 
                      <Alert severity="error" onClose={this.dismissError}>
                    <AlertTitle>Error</AlertTitle>
                        Invalid Credentials — <strong>Try Again</strong>
                    </Alert>
                  }
              
              {/* </Link> */}
              </p>
       
            <div className="space"></div>
            
          </div>
            </div>
          );

    }
    
  }
  
  export default Login;